<template>
  <div class="order">
    <h3 class="page-title">套装订单详情</h3>
    <b-card v-if="loading">
      <b-card-title>
        <ske width="6em"/>
      </b-card-title>
      <b-row align-v="center">
        <b-col cols="4">
          <ske type="square" height="75%"/>
        </b-col>
        <b-col>
          <ske block width="10em"></ske>
          <ske block width="7em"></ske>
          <ske block width="5em"></ske>
          <ske width="4em"></ske>
        </b-col>
      </b-row>
    </b-card>

    <template v-else-if="isGiftcards">
      <b-card title="物流信息" class="logistics">
        <div class="pt-2">
          <b-row v-for="(item, i) in records" :key="i">
            <b-col cols="auto" class="nowrap">
              <b :class="{'mid-date': i > 0 && records[i - 1].date === item.date}">
                {{item.date}}
              </b>
              <span class="ml-2">{{item.time}}</span>
            </b-col>
            <b-col cols="12" sm v-html="item.content"/>
          </b-row>
          <empty v-if="!records.length" icon="truck">暂无物流信息</empty>
        </div>

        <div class="mt-1 text-right" v-if="order.shipNo">
          <ship :co="order.shipCompany" :no="order.shipNo" class="text-muted">
            前往快递公司网站查询
          </ship>
        </div>
      </b-card>

      <b-card title="订单信息">
        <table class="table table-borderless mb-0">
          <tbody>
          <tr v-if="order.consignee">
            <td>收货人</td>
            <td>{{order.consignee}}</td>
          </tr>
          <tr v-if="order.phone">
            <td>联系电话</td>
            <td>{{order.phone}}</td>
          </tr>
          <tr v-if="order.addr">
            <td>地址</td>
            <td>{{order.addr.split('|').join(' ')}}</td>
          </tr>
          <tr v-if="order.note">
            <td>留言</td>
            <td>{{order.note}}</td>
          </tr>
          <tr v-if="order.status >= 0">
            <td>订单状态</td>
            <td>{{order.status | orderStatus}}</td>
          </tr>
          <tr>
            <td>支付方式</td>
            <td>{{payType}}</td>
          </tr>
          <tr v-if="order.shipCompany && !isPdf">
            <td>物流</td>
            <td>
              <span v-if="/shunfeng|顺丰/.test(order.shipCompany)">顺丰快递</span>
              <span v-else>{{order.shipCompany | shipCompany}}</span>
              <span v-if="order.shipNo">{{order.shipNo}}</span>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <hr class="my-0">
            </td>
          </tr>

          <tr v-if="order.package">
            <td>订单名称</td>
            <td>{{order.name}}</td>
          </tr>
          <tr v-if="order.price >= 0">
            <td>价格</td>
            <td>{{$rmb(order.price)}}</td>
          </tr>
          <tr v-if="order.shipMoney">
            <td>邮费</td>
            <td>{{$rmb(order.shipMoney)}}</td>
          </tr>
          <tr>
            <td>实付</td>
            <td>{{$rmb(order.paidMoney)}}</td>
          </tr>
          <tr v-if="order.couponNo && order.couponPrice">
            <td>优惠券</td>
            <td>
              {{order.couponNo}} /
              {{order.couponPrice | couponValue}}
            </td>
          </tr>
          <tr v-if="order.book && order.book.redeemCode">
            <td>兑换码</td>
            <td>{{order.book.redeemCode}}</td>
          </tr>
          <tr>
            <td>数量</td>
            <td>{{order.count}} 套</td>
          </tr>
          <tr v-if="order.bindingName">
            <td>装帧</td>
            <td>{{order.bindingName}}</td>
          </tr>
          <tr v-if="order.invoice">
            <td>发票信息</td>
            <td>{{order.invoice.title}} / {{order.invoice.category}}</td>
          </tr>

          <tr>
            <td colspan="2">
              <hr class="my-0">
            </td>
          </tr>

          <tr>
            <td>订单号</td>
            <td>{{order.id}}</td>
          </tr>
          <tr v-if="order.paidTime">
            <td>支付时间</td>
            <td>
              <datetime :value="order.paidTime" full/>
            </td>
          </tr>
          <tr>
            <td>创建时间</td>
            <td>
              <datetime :value="order.createTime" full/>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card>
    </template>

    <template v-else-if="isEmpty">
      <b-card>
        套装内容为空，请联系客服
      </b-card>
    </template>

    <template v-else>
      <template v-if="coupons.length">
        <coupon :value="coupon" v-for="coupon in coupons" :key="coupon.couponNo"/>
      </template>
      <b-card v-if="redeemCodes.length">
        <b-card-title>兑换码（共 {{redeemCodes.length}} 张）</b-card-title>
        <b-row align-v="center" v-for="item in redeemCodes" :key="item.code">
          <b-col cols="5">
            <square :src="item.product.hero" height="75%"/>
          </b-col>
          <b-col>
            <h5 class="mb-0">{{item.product.title}}兑换券</h5>
            <div>兑换码 <b>{{item.code}}</b></div>
            <div class="text-muted mb-2">
              <b-badge v-if="item.orderId" variant="primary">已下单</b-badge>
              <datetime :value="item.redeemTime" v-if="item.redeemTime" prefix="兑换于 "/>
              <datetime :value="item.expireAt" prefix="兑换截止时间 " v-else-if="item.expireAt"/>
              <span v-else>永久可用</span>
            </div>
            <b-btn v-if="item.aid && item.friend" disabled>
              <fa icon="gift"/>
              好友已使用
            </b-btn>

            <template v-else-if="item.aid">
              <b-btn :to="`/albums/${item.aid}`">查看作品</b-btn>
              <b-btn v-if="item.orderId" :to="'/orders/' + item.orderId">查看订单</b-btn>
            </template>

            <template v-else-if="item.expired">
              <b-btn disabled>兑换码已过期</b-btn>
            </template>

            <template v-else>
              <b-btn variant="primary" :to="`/redeem?code=${item.code}&tid=${item.tid}`">
                前往兑换
              </b-btn>
              <b-btn @click="copyCode(item)">
                <fa icon="gift"/>
                送好友
              </b-btn>
            </template>
          </b-col>
        </b-row>
      </b-card>
    </template>

    <b-btn @click="$router.go(-1)" block>返回</b-btn>
  </div>
</template>

<script>
import { chain, orderBy } from 'lodash'
import route from '@/mixins/route-data'
import clipboardCopy from 'clipboard-copy'
import { fetchAlbum } from '@/models/album'

export default {
  name: 'packageOrder',
  components: {
    Coupon: require('@/components/Coupon').default,
    Ship: require('@/components/Shipment').default
  },
  mixins: [
    route('order'),
    route('orderCodes'),
    route('albumProducts')
  ],
  data() {
    return {
      albums: []
    }
  },
  computed: {
    isGiftcards() {
      return this.order?.package?.category === 'physical'
    },
    isEmpty() {
      return !this.coupons.length && !this.redeemCodes.length
    },
    coupons() {
      return this.orderCodes.coupons || []
    },
    redeemCodes() {
      const results = this.orderCodes.redeemCodes || []
      results.forEach(i => {
        i.product = this.albumProducts.find(p => p.tid === i.tid) || {}
        i.orderId = i.orderIds[0] || ''
        i.album = this.albums.find(a => a.aid === i.aid)
        i.expired = new Date() > new Date(i.expireAt)
        if (i.album) {
          i.friend = i.album.uid !== this.user.unionid
        }
      })
      return orderBy(results, ['expireAt', 'redeemTime'], ['desc', 'desc'])
    },
    payType() {
      if (!this.order.paidType) {
        return ''
      }
      return {
        balance: '余额支付',
        wxpay: '微信支付',
        alipay: '支付宝'
      }[this.order.paidType.split('_')[0]] || '其他'
    },
    records() {
      const order = this.order
      const records = order.logistics?.records || order.logistics?.data || []
      if (!records.length && order.paidTime) {
        records.push({
          time: order.paidTime,
          content: '商品已经下单'
        })

        if (order.shipTime && order.shipNo) {
          records.push({
            time: order.shipTime,
            content: '等待快递揽收'
          })

          if (this.$day(order.shipTime).diff(new Date(), 'hour') < -24) {
            records.push({
              time: new Date(),
              content: '暂无更多物流信息，请点击下方链接查看'
            })
          }
        }
      }

      return chain(records).map(i => {
        const moment = this.$day(i.time || i.AcceptTime)
        const content = i.content || i.context || i.AcceptStation
        const date = moment.format('YYYY-MM-DD')
        const time = moment.format('HH:mm:ss')
        const datetime = moment.toJSON()
        return {date, datetime, time, content}
      }).orderBy(i => new Date(i.datetime), 'desc').value()
    }
  },
  methods: {
    async onLoad() {
      this.albums = await Promise.all(this.redeemCodes.filter(i => i.aid).map(i => {
        return fetchAlbum({albumId: i.aid}, true)
      }))
    },
    copyCode(item) {
      const url = 'https://weixinshu.com/redeem?code=' + item.code + '&tid=' + item.tid
      clipboardCopy(url).then(() => {
        this.$alert.success('兑换链接已复制，请发送给好友')
      }).catch(() => {
        this.$dialog.confirm({
          title: '赠送给好友',
          content: '请复制下面的兑换链接发送给好友<br><br><b>' + url + '</b>',
          okOnly: true
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.row + .row {
  margin-top: $card-columns-gap;
}

.order {
  max-width: 640px;

  .logistics {
    word-break: break-all;
  }

  .table {
    margin-bottom: 0;
  }

  td:first-child {
    padding-left: 0;
    white-space: nowrap;
    width: 1%;
  }

  td:last-child {
    padding-right: 0;
  }

  @include media-breakpoint-up(sm) {
    .mid-date {
      visibility: hidden;
      pointer-events: none;
    }
  }
}
</style>
