<template>
  <div class="package-orders">
    <h2 class="page-title">
      套装订单
      <div class="float-right">
        <b-btn to="/packages">再来一单</b-btn>
        <b-btn v-if="$store.state.prevRoute" @click="$router.back()">
          <fa icon="chevron-left"/>
          返回
        </b-btn>
        <b-btn to="/orders" v-else>
          <fa icon="chevron-left"/>
          返回订单列表
        </b-btn>
      </div>
    </h2>
    <b-row>
      <template v-if="loading">
        <b-col v-for="i in 2" :key="i" sm="6" lg="4">
          <b-card>
            <p>
              <ske type="square" height="75%"></ske>
            </p>
            <b-card-title>
              <ske width="10em"></ske>
            </b-card-title>
            <ske block width="10em"></ske>
            <ske block width="8em"></ske>
            <ske block width="4em"></ske>
          </b-card>
        </b-col>
      </template>
      <template v-else>
        <b-col v-for="item in orders" :key="item.id" sm="6" lg="4">
          <b-card>
            <p>
              <b-link :to="`/package-orders/${item.id}`">
                <square :src="item.package.hero" height="75%"/>
              </b-link>
            </p>
            <b-card-title class="text-overflow">{{item.package.title || item.name}}</b-card-title>
            <div class="text-muted">
              <div>
                <datetime :value="item.createTime"/> / {{item.paid ? '已付款' : '未付款'}}
              </div>
              <div>{{$rmb(item.paidMoney)}} / 共 {{item.count}} 套</div>
            </div>
            <div class="mt-2">
              <b-btn :to="'/package-orders/' + item.id" v-if="item.addr">查看详情</b-btn>
              <b-btn variant="primary" :to="`/package-orders/${item.id}`" v-else>
                <fa icon="gift"/>
                马上订制产品
              </b-btn>
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'packageOrders',
  mixins: [
    route('packages'),
    route('packageOrders', {alias: 'orders'})
  ],
  methods: {
    onLoad() {
      return this.orders.forEach(order => {
        order.package = this.packages.find(p => p.packageNo === order?.package?.packageNo) || {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.package-orders {
}
</style>
